import styled from 'styled-components'
import { Button } from '../../components/v2Components/Button'
import { TableBase } from '../NewUserInvestments/components'

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .body {
    width: 100%;
    max-width: 1800px;
    padding-bottom: 40px;
  }
`
export const Title = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin: 24px 0px;

  color: #076f9f;
`
export const Subtitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #424242;
`

export const Infotitle = styled.h3`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #076f9f;
`

export const ContainerFilter = styled.div`
  display: grid;
  grid-template-columns: 541px 1fr 1fr;
  grid-template-areas: 'filter judicial search';

  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;

  .filter {
    padding: 24px 32px 24px 0px;
    border-right: 1px solid #e0e0e0;
    grid-area: filter;
  }

  .judicial {
    padding: 24px 32px;
    border-right: 1px solid #e0e0e0;
    grid-area: judicial;
  }

  .search {
    padding: 24px 0px 24px 32px;
    grid-area: search;
  }

  @media (max-width: 1370px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'filter filter'
      'judicial search';

    .filter {
      padding: 24px 32px 24px 0px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'filter'
      'judicial'
      'search';

    .filter {
      padding: 24px 32px 24px 25px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }

    .judicial {
      border-bottom: 1px solid #e0e0e0;
      border-right: 0px solid #e0e0e0;
    }

    .search {
      padding: 24px 32px 24px 32px;
    }
  }
`

export const ButtonFilter = styled(Button)``

export const Table = styled(TableBase)`
  .MuiTableCell-body {
    font-size: 16px;
    padding: 12px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    box-sizing: border-box;
  }

  .MuiTableCell-root:last-child {
    max-width: 200px;
    width: 250px;
  }
`
