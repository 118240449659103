/* eslint-disable indent */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import moment from 'moment-timezone'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Chip, Avatar } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { LoadingComponent } from '../../components'
import {
  AutocompleteMulti,
  AutocompleteMultiLarger,
  Button,
  Radio,
  Checkbox,
  Column,
  Row
} from '../../components/v2Components'

import { IconCalendar } from '../../components/v2Components/icons'
import DoneIcon from '@material-ui/icons/Done'
import {
  Body,
  Subtitle,
  Table,
  ContainerBank,
  Card,
  ContainerCard,
  Grid,
  Bank,
  Parcel,
  Judicial,
  Generator
} from './style'

import { formatCpf, formatCurrency } from '../../helpers'
import {
  getInstallmentsConsultPayments,
  consultPayments,
  generatorPayments,
  generatorPaymentsCNAB
} from '../../services/v3'

const ConsultPayments = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [openInstallments, setOpenInstallments] = useState([])
  const [judicial, setJudicial] = useState()
  const [checkAgroup, setCheckAgroup] = useState(false)
  const [judicialInstallments, setJudicialInstallments] = useState([])
  const [filterInstallments, setFilterInstallments] = useState([])
  const [fileData, setFileData] = useState(new Date())
  const [payDay, setPayDay] = useState(null)
  const [bankFilter, setBankFilter] = useState('all')
  const [listBank, setListBank] = useState([])

  const { control, errors, watch, setValue } = useForm({
    defaultValues: { judicial: [], installments: [], filterData: 'stark', agroup: true }
  })

  const installmentsSelected = watch('installments')
  const JudicialSelected = watch('judicial')
  const generatorBank = watch('filterData')

  const options = useMemo(() => {
    if (bankFilter === 'itau') {
      return [
        {
          value: 'cnab',
          label: 'CNAB'
        }
      ]
    } else {
      return [
        {
          value: 'stark',
          label: 'Stark'
        },
        {
          value: 'atar',
          label: 'Atar'
        }
      ]
    }
  }, [bankFilter])

  const simulationColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'campaign_id',
        minWidth: 30,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Campanha',
        accessor: 'name',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Parce',
        accessor: 'installment_number',
        minWidth: 60,

        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Inv',
        accessor: 'investor_id',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Investidor',
        accessor: 'investor_full_name',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'CPF',
        accessor: 'document_number',
        minWidth: 190,
        Cell: props => <p>{formatCpf(props.value)}</p>
      },
      {
        Header: 'Líquido',
        accessor: 'net_value',
        minWidth: 190,
        Cell: props => <p>{formatCurrency(props.value)}</p>
      },
      {
        Header: 'ISPB',
        accessor: 'ispb',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Agen.',
        accessor: 'agency',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Conta',
        accessor: 'account',
        minWidth: 190,
        Cell: props => <p>{`${props.value}`}</p>
      },
      {
        Header: 'Banco',
        accessor: 'code',
        minWidth: 100,
        Cell: props => <p>{`${props.value}`}</p>
      },
      {
        Header: 'DV',
        accessor: 'account_digit',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      },
      {
        Header: 'Bruto',
        accessor: 'gross_amount',
        minWidth: 190,
        Cell: props => <p>{formatCurrency(props.value)}</p>
      },
      {
        Header: 'Tipo.',
        accessor: 'type',
        minWidth: 190,
        Cell: props => <p>{props.value}</p>
      }
    ],
    []
  )

  const optionsInstall = useMemo(() => {
    const installments = openInstallments.map(item => {
      return {
        value: item,
        label: `${item.campaign.id} - ${item.installment_number} - ${item.campaign.name} - ${moment(item.expire_date)
          .tz('America/Danmarkshavn')
          .format('DD/MM/YYYY')}`
      }
    })

    if (typeof JudicialSelected !== 'string' && JudicialSelected.length > 0) {
      const judicialList = judicialInstallments
        .filter(item => JudicialSelected.some(intall => intall.value.campaign_id === item.campaign_id))
        .map(item => {
          return {
            value: item,
            label: `${item.campaign.id} - ${item.installment_number} - ${item.campaign.name} - ${moment(
              item.expire_date
            )
              .tz('America/Danmarkshavn')
              .format('DD/MM/YYYY')}`
          }
        })

      return [...installments, ...judicialList]
    }

    return installments
  }, [judicialInstallments, JudicialSelected, openInstallments])

  const cardInfo = useMemo(() => {
    if (filterInstallments.length > 0) {
      let liquidValue = 0
      let bruteValue = 0
      let numerCurrent = 0
      let valueCurrent = 0
      let numerSavings = 0
      let valueSavings = 0

      filterInstallments.map(item => {
        liquidValue = liquidValue + item.net_value
        bruteValue = bruteValue + item.gross_amount

        if (item.type === 'CC') {
          numerCurrent = numerCurrent + 1
          valueCurrent = valueCurrent + item.net_value
        } else {
          numerSavings = numerSavings + 1
          valueSavings = valueSavings + item.net_value
        }

        return null
      })

      return {
        liquidValue: formatCurrency(liquidValue),
        bruteValue: formatCurrency(bruteValue),
        valueCurrent: `${formatCurrency(valueCurrent)} (${numerCurrent})`,
        valueSavings: `${formatCurrency(valueSavings)} (${numerSavings})`
      }
    } else {
      return {}
    }
  }, [filterInstallments])

  const handleGenerator = useCallback(async () => {
    try {
      const element = document.createElement('a')
      if (generatorBank === 'stark' || generatorBank === 'atar') {
        const { data: response } = await generatorPayments({
          bank: generatorBank,
          installments: filterInstallments
        })
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        element.download = `Consulta-de-pagamentos-${generatorBank}.xlsx`
        element.click()
      } else {
        const { data: response } = await generatorPaymentsCNAB(
          moment(fileData).format('DDMMYYYY'),
          moment(payDay).format('DDMMYYYY'),
          filterInstallments.map(item => item.id).join()
        )
        element.href = URL.createObjectURL(
          new Blob([response], {
            type: 'text/plain'
          })
        )
        element.download = `cnab${moment(fileData).format('MMYY')}.txt`
        element.click()
      }
    } catch (error) {
      toast.error('Erro ao gerar arquivo de pagamentos')
    }
  }, [filterInstallments, generatorBank, fileData, payDay])

  const fetchInfos = useCallback(async () => {
    try {
      const { data: response } = await getInstallmentsConsultPayments()

      setOpenInstallments(response.standard_campaigns)

      setJudicialInstallments(response.campaigns_in_execution)

      const setInstall = new Set()
      setJudicial(
        response.campaigns_in_execution
          .map(item => {
            return { value: item, label: `${item.campaign.id} - ${item.campaign.name}` }
          })
          .filter(item => {
            const duplicatedInstallment = setInstall.has(item.value.campaign.id)
            setInstall.add(item.value.campaign.id)
            return !duplicatedInstallment
          })
      )
    } catch {
      toast.error('Não foi possível encontrar as parcelas da campanha.')
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (bankFilter !== 'itau') {
      setValue('agroup', true)
      setCheckAgroup(true)
      setValue('filterData', 'stark')
    } else {
      setValue('filterData', 'cnab')
      setValue('agroup', false)
      setCheckAgroup(false)
    }
  }, [bankFilter])

  useEffect(() => {
    setPayDay(fileData)
  }, [fileData])

  useEffect(() => {
    fetchInfos()
  }, [])

  useEffect(() => {
    const installFetch = async () => {
      try {
        if (typeof installmentsSelected !== 'string') {
          const { data: response } = await consultPayments({
            params: installmentsSelected.map(item => {
              return {
                campaign_id: item.value.campaign_id,
                installment_number: item.value.installment_number
              }
            })
          })

          let numberBank = response.reduce((acc, currentValue) => {
            if (Number(currentValue.code) === 341) {
              return acc + 1
            } else {
              return acc + 0
            }
          }, 0)

          setListBank([
            { key: 0, label: `Itaú (${numberBank})`, img: '/assets/icons/itau.png', set: 'itau' },
            { key: 1, label: `Outros (${response.length - numberBank})`, set: 'others' },
            { key: 2, label: `Todos (${response.length})`, set: 'all' }
          ])

          const filteredResponse =
            bankFilter === 'itau'
              ? response.filter(item => Number(item.code) === 341)
              : bankFilter === 'others'
              ? response.filter(item => Number(item.code) !== 341)
              : response
          if (checkAgroup) {
            const resp = filteredResponse
            const setInstall = new Set()

            setFilterInstallments(
              resp
                .filter(item => {
                  const duplicatedInstallment = setInstall.has(item.investor_id)
                  setInstall.add(item.investor_id)
                  return !duplicatedInstallment
                })

                .map(item => {
                  const newNetvalue = filteredResponse.reduce((acc, currentValue) => {
                    if (currentValue.investor_id === item.investor_id) {
                      return acc + currentValue.net_value
                    } else {
                      return acc + 0
                    }
                  }, 0)
                  const newGrossAmount = filteredResponse.reduce((acc, currentValue) => {
                    if (currentValue.investor_id === item.investor_id) {
                      return acc + currentValue.gross_amount
                    } else {
                      return acc + 0
                    }
                  }, 0)
                  item.gross_amount = newGrossAmount
                  item.net_value = newNetvalue
                  return item
                })
            )
          } else {
            setFilterInstallments(filteredResponse)
          }
        }
      } catch (error) {}
    }

    installFetch()
  }, [JudicialSelected, installmentsSelected, bankFilter, checkAgroup])

  if (isLoading) return <LoadingComponent />

  return (
    <Body>
      <Grid>
        <Parcel>
          <Subtitle style={{ marginTop: '10px' }}>Parcelas em aberto</Subtitle>
          <AutocompleteMultiLarger
            error={errors.installments}
            name='installments'
            disableCloseOnSelect={true}
            options={optionsInstall}
            control={control}
            label='Parcelas em aberto'
            multiple
            creatable={false}
            width='100%'
            mt='25px'
            mb='10px'
          />
        </Parcel>
        <Judicial>
          <Subtitle style={{ marginTop: '10px' }}>Execução judicial</Subtitle>
          <AutocompleteMulti
            error={errors.judicial}
            name='judicial'
            disableCloseOnSelect={true}
            options={judicial}
            control={control}
            label='Campanhas em Execução judicial'
            multiple
            creatable={false}
            width='100%'
            mt='25px'
            mb='10px'
          />
        </Judicial>

        <Generator>
          <Subtitle style={{ marginTop: '10px' }}>Gerar arquivos</Subtitle>
          <Row style={{ gap: '25px', marginTop: '10px' }}>
            <KeyboardDatePicker
              variant='inline'
              format='DD/MM/yyyy'
              margin='normal'
              id='date-picker-inline'
              views={['year', 'month', 'date']}
              label='Data do Arquivo'
              value={fileData}
              invalidDateMessage={'Data inválida'}
              inputVariant='outlined'
              onChange={data => setFileData(data)}
              InputProps={{
                style: {
                  fontSize: 16,
                  height: 40
                }
              }}
              InputLabelProps={{
                shrink: true
              }}
              keyboardIcon={<IconCalendar />}
            />

            <KeyboardDatePicker
              variant='inline'
              format='DD/MM/yyyy'
              margin='normal'
              id='date-picker-inline'
              views={['year', 'month', 'date']}
              label='Data do Pagamento'
              value={payDay}
              invalidDateMessage={'Data inválida'}
              inputVariant='outlined'
              onChange={data => setPayDay(data)}
              InputProps={{
                style: {
                  fontSize: 16,
                  height: 40
                }
              }}
              style={{ height: '40px' }}
              InputLabelProps={{
                shrink: true
              }}
              keyboardIcon={<IconCalendar />}
            />
          </Row>

          <Column alignItems='flex-end'>
            <Radio options={options} control={control} name='filterData' error={errors.filterData} />
            <Button mb='10px' onClick={handleGenerator} width='127px'>
              Gerar
            </Button>
          </Column>
        </Generator>

        {listBank.length > 0 && (
          <Bank>
            <Subtitle style={{ marginTop: '10px' }}>Filtrar por bancos</Subtitle>
            <ContainerBank>
              {listBank.map(bank => (
                <Chip
                  label={bank.label}
                  avatar={!!bank.img ? <Avatar alt={bank.label} src={bank.img} /> : ''}
                  onClick={() => setBankFilter(bank.set)}
                  onDelete={() => setBankFilter(bank.set)}
                  color={bank.set === bankFilter ? 'primary' : 'default'}
                  deleteIcon={<DoneIcon />}
                />
              ))}
              <Checkbox
                checked={checkAgroup}
                label='Agrupar'
                onChange={value => {
                  setValue('agroup', value.target.checked)
                  setCheckAgroup(value.target.checked)
                }}
                control={control}
                defaultValue={false}
                name='agroup'
                error={errors.agroup}
              />
            </ContainerBank>
          </Bank>
        )}

        {filterInstallments.length > 0 && (
          <ContainerCard style={{ gridArea: 'info' }}>
            <Card>
              <label>Total de Líquido:</label>
              <p>{cardInfo.liquidValue}</p>
            </Card>
            <Card>
              <label>Total Bruto:</label>
              <p>{cardInfo.bruteValue}</p>
            </Card>
            <Card>
              <label>Corrente:</label>
              <p>{cardInfo.valueCurrent}</p>
            </Card>
            <Card>
              <label>Poupança:</label>
              <p>{cardInfo.valueSavings}</p>
            </Card>
          </ContainerCard>
        )}
      </Grid>

      <Table hasPaginationTop hasPaginationBottom columns={simulationColumns} data={filterInstallments} length={50} />
    </Body>
  )
}

export default ConsultPayments
